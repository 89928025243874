import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const PrimaryLink = ({ text, to, className, isSmall }) => (
  <Link
    className={`${className} bg-green hover:bg-green-700 text-white ${
      isSmall ? 'py-2 px-4' : 'py-4 px-7'
    } text-md shadow-sm rounded-sm text-center inline-block`}
    to={to}
    rel="noreferrer"
  >
    {text}
  </Link>
);
export default PrimaryLink;

PrimaryLink.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  isSmall: PropTypes.bool,
};
