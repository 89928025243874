import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-react-intl';

import SEO from './SEO';
import metaImage from '../images/meta.png';

export default function LayoutSignUpFarmers({ children }) {
  const intl = useIntl();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line global-require
      require('smooth-scroll')('a[href*="#"]', {
        updateURL: false,
        offset: (anchor) => (anchor.id === 'be-part' ? -50 : 50),
      });
    }
  }, []);

  return (
    <div>
      <SEO
        title={intl.formatMessage({ id: 'fullTitle' })}
        description={intl.formatMessage({ id: 'description' })}
        lang={intl.locale}
        imgPath={metaImage}
        imgAlt="La infraestructura global de tokenización de agrocommodities"
        meta={[]}
      />
      {children}
    </div>
  );
}

LayoutSignUpFarmers.propTypes = {
  children: PropTypes.node.isRequired,
};
