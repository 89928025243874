import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { H15 } from '../../components/Texts';
import PrimaryLink from '../../components/Buttons/PrimaryLink';
import agrotoken from '../../images/logo_standard.svg';
import tokens from '../../images/Tokens.png';
import tick from '../../images/icons/tick.svg';

import LayoutSignUpFarmers from '../../components/layoutSignUpFarmers';

const Success = () => {
  const intl = useIntl();

  return (
    <LayoutSignUpFarmers>
      <div className="h-screen flex flex-col justify-between">
        <div className="m-auto px-4 pt-24 lg:w-7/12 lg:flex-shrink-0">
          <div className="mb-8 w-full flex justify-center">
            <img
              src={agrotoken}
              alt="Logo Agrotoken"
              className="inline-block max-h-6"
            />
          </div>
          <div className="mb-8 w-full flex justify-center">
            <div
              className={`flex justify-center items-center p-3.5 bg-primary-100 text-primary-600 border-10 border-primary-50 rounded-full`}
            >
              <img src={tick} alt="Logo Agrotoken" className="inline-block" />
            </div>
          </div>
          <H15 className="mb-3 text-center">
            {intl.formatMessage({ id: 'farmersSignUp.success' })}
          </H15>
          <p className="mb-6 text-center">
            {intl.formatMessage({ id: 'farmersSignUp.text2' })}
          </p>
          <div className="flex justify-center w-fit">
            <PrimaryLink
              text={intl.formatMessage({
                id: 'farmersSignUp.backToHome.text',
              })}
              to={intl.formatMessage({ id: 'farmersSignUp.backToHome.url' })}
              className="text-center mt-8"
              isSmall={true}
            />
          </div>
        </div>
        <div className="hidden lg:flex justify-center items-end h-1/2">
          <img src={tokens} alt="tokens" className="h-full max-h-[464px]" />
        </div>
      </div>
    </LayoutSignUpFarmers>
  );
};

export default Success;
